.sBox {
    background-color: #d2d2d2;
    padding: 40px;
    justify-content: center;
    text-align: center;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

@media (min-width: 768px) {
  .sBox {
    background-color: #d2d2d2;
    padding: 40px;
    justify-content: center;
    text-align: center;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 200px;
}
}

.sImg {
    display: block;
    margin: 0 auto;
    width: 100%;
    object-fit: cover;
    height: 300px;
    object-position: center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    
  }

  .sTitle {
    margin-top: -10px;
    text-decoration: underline;
  }
  
  @media (max-width: 767px) {
    .Obackground-image {
      height: 200px; /* or whatever height you want to use for mobile */
      position: absolute; 
      top: 20;
      left: 0; 
      z-index: -1; 
      width: 100%;
      object-fit: cover;
      object-position: calc(40%) center; /* move image to the right by 50 pixels */
      bottom: auto;
      opacity: 0.9;
      filter: brightness(0.5); /* Adjust the brightness value as desired */

    }
  }
  
  /* For screens larger than 768px (i.e. desktop devices) */
  @media (min-width: 768px) {
    .Obackground-image {
      height: 300px; /* or whatever height you want to use for desktop */
      max-height: 550px;
      position: absolute; 
      top: 20;
      left: 0; 
      z-index: -1; 
      width: 100%;
      object-fit: cover;
      object-position: center;
      bottom: auto;
      opacity: 0.9;
      filter: brightness(0.5); /* Adjust the brightness value as desired */

      
    }
  }

  .imgBr {
    margin-bottom: 225px;
  }
  @media (min-width: 768px) {
    .imgBr {
        margin-bottom: 350px;
      }
  }


  .titleS {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-align: center;
    background: linear-gradient(to right, #fff, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 300%);
}

  
  

  @media (min-width: 768px) {
    .titleS {
      font-size: 50px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      text-align: center;
      background: linear-gradient(to right, #fff, #fff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 190%);
      }
  }
