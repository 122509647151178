
.home {
  justify-content: center;
}

/* For screens smaller than 768px (i.e. mobile devices) */
@media (max-width: 767px) {
.background-image {
  height: 250px; /* or whatever height you want to use for desktop */
  position: absolute; 
  left: 0; 
  z-index: -1; 
  width: 100%;
  object-fit: cover;
  object-position: center ; /* move image to the right by 50 pixels */
  bottom: auto;

}
}

/* For screens larger than 768px (i.e. desktop devices) */
@media (min-width: 768px) {
.background-image {
  height: 400px; /* or whatever height you want to use for desktop */
  position: relative; 
  top: 50%; /* move the top edge of the element to the middle of the screen */
  left: 50%; /* move the left edge of the element to the middle of the screen */
  transform: translate(-50%, 0%); /* move the element back up and left by half its width and height */
  z-index: -1; 
  width: 100vw;
  object-fit: cover;
  object-position: center;
  bottom: auto;
}    

}


@media (max-width: 767px) {
  .imgDiv {
    height: 530px;
  }

}



@media (max-width: 767px){
.MainWords {
  font-family: 'Jomhuria', cursive;  font-size: 40px;
  color: black;
}
}
@media (min-width: 767px){
  .MainWords {
    font-family: 'Jomhuria', cursive;    font-size: 80px;
    color: black;
    margin-bottom: -20px;
}
  }
  @media (max-width: 767px){
    .MainWords2 {
      font-family: 'Jomhuria', cursive;  font-size: 32px;
      color: black;
    }
    }
    @media (min-width: 767px){
      .MainWords2 {
        font-family: 'Jomhuria', cursive;    font-size: 30px;
        color: black;
        margin-bottom: 20px;
      }
      }

.journeyButton{
    height: 20px;
    width: 100px;
}
@media (max-width: 767px){
.actionCol {
position: absolute;
left: 10%;
margin-top: -150px;
transform: translate(-20%, -110%);
display: block;
justify-content: right;
align-items: right;
text-align: right;
z-index: 6;

}
}
@media (min-width: 767px){
.actionCol {
margin-top: 200px;
margin-bottom: -150px;
position: relative;
left: 10%;
transform: translate(-30%, -160%);
display: block;
justify-content: right;
align-items: right;
text-align: right;
}
}

/* For screens smaller than 768px (i.e. mobile devices) */
@media (max-width: 767px) {
.underWords {
font-family: 'Times New Roman', Times, serif;
font-size: 25px;
color: black;
text-align: center;
justify-content: center;
}
}

/* For screens larger than 768px (i.e. desktop devices) */
@media (min-width: 768px) {
.underWords {
font-family: 'Times New Roman', Times, serif;
font-size: 60px;
color: black;
text-align: center;
justify-content: center;
}
}




.underPar {
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
    color: black;
    max-width: 85vw; 
    margin: 0 auto;
    text-align: center;
}

@media (min-width: 768px) {
  .underPar {
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
    color: black;
    max-width: 65vw; 
    margin: 0 auto;
    text-align: center;
}
  }


.underRow {
  margin-bottom: -23px;
  padding-top: 20px;
}




@media (max-width: 767px) {
.info-slider {
  padding-top: 500px; /* or whatever height you want to use for mobile */

  position: relative;
top: 0;
display: flex;
justify-content: center;
align-items: center;
}
}

/* For screens larger than 768px (i.e. desktop devices) */
@media (min-width: 768px) {
.info-slider {
position: relative;
top: 0;
display: flex;
justify-content: center;
align-items: center;
}}

.youtubeVid {
padding-bottom: 56.25%; 
height: 0;
position: relative;
left: 0;
right: 0;
}