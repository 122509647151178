  @media (max-width: 767px) {
    .logo2 {
      height: 60px; /* or whatever height you want to use for mobile */
    }
  }
  
  /* For screens larger than 768px (i.e. desktop devices) */
  @media (min-width: 768px) {
    .logo2 {
      height: 60px; /* or whatever height you want to use for desktop */
    }
  }


.navbar {
  z-index: 8;
}


